@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.about {
  background-image: url('../../assets/about-background.png');
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include mq-tablet--down {
    height: auto;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.aboutUsText {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  font-size: 1.85rem;
  text-align: center;
  right: 1rem;
  left: 536px;

  @include mq-desktop-l--down {
    font-size: 1.7rem;
  }

  @include mq-desktop--down {
    left: 428.8px;
    font-size: 1.45rem;
  }

  @include mq-laptop--down {
    left: 321.5px;
    font-size: 1.2rem;
  }

  @include mq-tablet--down {
    position: static;
  }

  & h2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  & p {
    margin: 0;
  }
}

.image--wrapper {
  position: relative;
  left: -536px;

  @include mq-desktop--down {
    left: -428.8px;
  }

  @include mq-laptop--down {
    left: -321.5px;
  }

  @include mq-tablet--down {
    display: none;
  }

  & img {
    cursor: pointer;
    @include mq-desktop--down {
      width: 80%;
    }

    @include mq-laptop--down {
      width: 60%;
    }
  }
}

.image--active {
  & img {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);   
    transition: transform 1s;
  }
}

.image--reset {
  & img {
  animation: activate 1s;
  }
}

@keyframes activate {
  from {transform: rotate(180deg);}
  to {transform: rotate(360deg);}
}

.circle-image {
  width: 75%;

  @include mq-tablet--up {
    display: none;
  }
}