@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.contact {
  position: relative;
  background-image: url("../../assets/contact-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  color: $white;
  font-size: 2rem;

  @include mq-tablet--up {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include mq-desktop--down {
    font-size: 1.7rem;
  }

  @include mq-tablet--down {
    flex-direction: column;
    font-size: 1.4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__column1,
  &__column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 5rem;
    text-align: center;

    @include mq-desktop--down {
      padding: 0rem 2.5rem;
    }

    @include mq-tablet--down {
      padding: 0rem 1rem;
    }
  }

  &__header {
    font-weight: 700;
    &:not(:first-child) {
      padding-top: 0.5rem;
    }

    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    padding-top: 0.5rem;

    & img {
      width: 3rem;
    }
  }

  &__column1 {
    & div:first-child {
      padding-bottom: 0;
    }
  }

  &__column2 {
    @include mq-tablet--down {
      margin-bottom: 1rem;
    }
  }

  &__social-media {
    padding-top: 2rem;

    & a {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    & img {
      height: 5rem;
      width: 5rem;
      cursor: pointer;

      @include mq-desktop--down {
        height: 4rem;
        width: 4rem;
      }
    }
  }

  &__terms {
    position: absolute;
    bottom: 0;
    left: 0;
    text-decoration: underline;
    color: blue;
    font-size: 1.5rem;
    cursor: pointer;

    @include mq-mobile--down {
      font-size: 1.2rem;
    }
  }

  &__modal {
    padding: 1rem 20rem;

    @include mq-desktop-l--down {
      padding: 1rem 15rem;
    }

    @include mq-desktop--down {
      padding: 1rem 10rem;
    }

    @include mq-laptop--down {
      padding: 1rem 5rem;
    }

    @include mq-tablet--down {
      padding: 1rem;
    }

    @include mq-mobile--down {
      padding: 0.25rem;
    }

    h2,
    h3 {
      text-align: center;
    }
  }

  &__modal-close {
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
