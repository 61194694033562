@import '../../styles/media.scss';

.map {
    @include mq-tablet--down {
        width: 80% !important;
    }

    @include mq-mobile--down {
        width: 100% !important;
    }
}