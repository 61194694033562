@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.nav {
  &__container {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 3rem;
    background-color: rgba(255, 255, 255, 0.7);
    top: 3rem;
    left: 0;
    width: 100%;
    z-index: 100;

    @include mq-tablet--down {
      justify-content: space-between;
      padding: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .button:hover + &__buttons-shadow {
    position: absolute;
    padding-top: 3rem;
    padding-bottom: 3.9rem;
    margin-top: -1rem;
    margin-bottom: -2rem;
    top: 0;
    left: 0.5rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($red, 0.6);
    border-bottom-right-radius: 1.5rem;
  }

  &__buttons-container {
    @include mq-tablet--down {
      display: none;
    }

    margin-left: 15%;
    display: flex;
    gap: 0.5rem;

    & > div {
      position: relative;
    }

    & button {
      font-size: 1.6rem;
      font-weight: 700;
      padding: 2.5rem 1rem;
      margin-top: -1.5rem;
      margin-bottom: -1.5rem;
      border-bottom-right-radius: 1.5rem;

      &:hover {
        background-color: $red;
        cursor: pointer;
        color: $white;
      }
    }
  }

  &__logo {
    width: 11rem;

    @include mq-tablet--down {
      width: 8rem;
    }
  }
}

.translation {
  position: absolute;
  top: 0.25rem;
  right: 0;
  z-index: 100;

  & img {
    width: 35px;
    height: 35px;
    margin-right: 0.75rem;
    cursor: pointer;
  }
}