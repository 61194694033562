@import './breakpoints.scss';

@mixin mq-tablet--up {
    @media screen and (min-width: #{($breakpoint-tablet + 1)}) {@content;}
}

@mixin mq-desktop--up {
    @media screen and (min-width: #{($breakpoint-desktop + 1)}) {@content;}
}

@mixin mq-tablet--down {
    @media screen and (max-width: #{($breakpoint-tablet - 1)}) {@content;}
}

@mixin mq-desktop--down {
    @media screen and (max-width: #{($breakpoint-desktop - 1)}) {@content;}
}

@mixin mq-laptop--down {
    @media screen and (max-width: #{($breakpoint-laptop - 1)}) {@content;}
}

@mixin mq-desktop-l--down {
    @media screen and (max-width: #{($breakpoint-desktop-l - 1)}) {@content;}
}

@mixin mq-mobile--down {
    @media screen and (max-width: #{($breakpoint-mobile - 1)}) {@content;}
}