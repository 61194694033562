@import "../../styles/media.scss";
@import "../../styles/variables.scss";

.menu {
  &--container {
    @include mq-tablet--up {
      display: none;
    }

    & > img {
      cursor: pointer;
    }
  }

  &__items {
    position: absolute;
    background-color: rgba($red, 0.7);
    padding: 0.5rem 0rem;
    display: flex;
    top: 0;
    right: 0;
    flex-direction: column;
    z-index: 999;
    border-bottom-left-radius: 1.5rem;

    & > button,
    a {
      color: $white;
      font-size: 1.5rem;
      font-weight: 300;
      padding: 1.5rem 2.5rem;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
