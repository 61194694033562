@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.news {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-image: url('../../assets/news-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  &__container {
    z-index: 10;
    margin-top: 9rem;
    margin-bottom: 2rem;

    &--relative {
      position: relative;
      display: flex;
      justify-content: flex-end;
      height: 700px;
      margin-right: 3rem;

      @include mq-tablet--down {
        justify-content: center;
        margin-right: 0;
        height: 80vw;
      }
    }
  }

  &__shadow {
    position: absolute;
    right: 3rem;
    width: 700px;
    height: 700px;
    background-color: rgba($red, 0.6);
    border-radius: 50%;
    z-index: -1;
    
    @include mq-tablet--down {
      width: 80vw;
      height: 80vw;
      right: auto;
      right: 15%;
    }
  }

  &__image-container {
    width: 700px;
    height: 700px;
    background-color: $red;
    border-radius: 50%;
    
    @include mq-tablet--down {
      width: 80vw;
      height: 80vw;
    }
  }

  &__image {
    // position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    
    @include mq-tablet--down {
      width: 80vw;
      height: 80vw;
    }
  }

  &__image-carousel {
    background-image: url('../../assets/logo2.png');
    background-position: center;
    background-repeat: space;
    background-color: $white;
    bottom: 0;
    height: 6rem;
    width: 100%;

    @include mq-tablet--down {
      display: none;
    }
  }

  &__logo-mobile {
    width: 70%;
    margin: auto;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;

    @include mq-tablet--up {
      display: none;
    }
  }

  &__background {
    width: 100%;
  }
}
